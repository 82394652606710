"use client";
// packages
import React, { useEffect, useState, useRef, useCallback, useMemo } from "react";
import { SubmitHandler, useForm, useWatch } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useRouter, usePathname, useSearchParams } from "next/navigation";
import { UseMutationResult, UseQueryResult } from "@tanstack/react-query";
import axios from "axios";
import { z } from "zod";
import { useDeepCompareMemo } from "@react-hookz/web";
import toast from "react-hot-toast";
import { useLayer } from "@statsig/react-bindings";
import Cookies from "js-cookie";

// components
import { Form } from "@/shared/components/Form";
import LoaderWithText from "@/shared/components/LoaderWithText";
import { Container } from "@/shared/components/Container";
import { FormParentContextProvider } from "@/shared/contexts/FormParent";
import { LoaderModal } from "@/shared/components/LoaderModal";
import { QuoteResetModal } from "@/shared/components/QuoteResetModal";
import { AmazonToast } from "./src/components/AmazonToast";

// hooks
import { useModal } from "@/shared/hooks/useModal";
import { useQuote } from "@/shared/hooks/useQuote";
import { useOtherValuesMap } from "@/shared/hooks/useOtherValuesMap";
import { useAppLayerContext } from "@/shared/contexts/AppLayer";
import { useNotifications } from "@/shared/hooks/useNotifications";
import { useDirtyValues } from "@/shared/hooks/useDirtyValues";
import { useBreakpoint } from "@/shared/hooks/useBreakpoint";

// utils
import { UsQuoteFormStepIds, getUsQuoteForm } from "./formConfig";
import { AnalyticsUtils } from "@/shared/utils/AnalyticsUtils";
import { UIUtils } from "@/shared/utils/UIUtils";
import { StorageUtils } from "@/shared/utils/StorageUtils";
import { QuoteAPI } from "@/shared/utils/QuoteAPI";
import { NotificationUtils } from "@/shared/utils/NotificationUtils";
import Strings from "@/shared/utils/Strings.constants";
import { PTZBillingUtils } from "../../src/types/TempBilling";

// types
import { Quote, QuoteFinalized, QuoteSchema } from "@/shared/types/Quote.interface";
import { FormStep } from "@/shared/types/Form";
import { ComponentWithUnderwriter } from "@/shared/types/AppRouter";
import { Address } from "spot-types/entities/Address";
import { Notifications, notificationSchema } from "@/shared/types/Notifications";
import { StatsigNameFields } from "@/shared/types/Statsig";

// config
import { PublicConfig } from "@/shared/PublicConfig";

const FORM_ID = PublicConfig.PTZ_US.FORM_ID;
const COOKIE_QUOTE_ID_KEY = PublicConfig.PTZ_US.COOKIE_QUOTE_ID_KEY;
const ACTIVE_OUTAGES = PublicConfig.PTZ_US.ACTIVE_OUTAGES;

export function UsQuoteFormController({ underwriter, testUserId, pageProps }: ComponentWithUnderwriter) {
    const quoteApi = useMemo(() => new QuoteAPI(underwriter), [underwriter]);
    const [showResetModal, setShowResetModal] = useState(false);
    const isBillingOutage = ACTIVE_OUTAGES.includes("ptz-us-billing-api");
    const currentBreakpoint = useBreakpoint();

    const submittingRef = useRef(false);
    const initialValuesLoadedRef = useRef(false);
    const [isFormReady, setIsFormReady] = useState(false);

    const modal = useModal();
    const { appState, updateAppState } = useAppLayerContext();

    // URL hooks
    const searchParams = useSearchParams();
    const router = useRouter();
    const pathname = usePathname();

    // quoteId
    const quoteIdParam = UIUtils.getCaseInsensitiveValue(new URLSearchParams(searchParams.toString()), `quoteId`);
    const [quoteId, setQuoteId] = useState(() => quoteIdParam ?? ``);

    // Server state
    const { queryClient, quoteQuery, updateQuote, isQuoteUpdating } = useQuote({ quoteId, underwriter, setQuoteId });
    const memoizedQuoteQuery = useDeepCompareMemo(() => (!!quoteQuery ? (quoteQuery as UseQueryResult<Quote, Error>) : undefined), [quoteQuery]);
    const memoizedUpdateQuote = useDeepCompareMemo(() => (!!updateQuote ? (updateQuote as UseMutationResult<Quote, Error, Quote, unknown>) : undefined), [updateQuote]);

    const { data: quote, isError } = memoizedQuoteQuery || {};

    // Verify email param
    const emailRegisterCheck = useMemo(() => {
        const emailRegisterCheck = UIUtils.getCaseInsensitiveValue(new URLSearchParams(searchParams.toString()), "verifyMC");
        return emailRegisterCheck === "true";
    }, [searchParams]);

    useEffect(() => {
        if (emailRegisterCheck) {
            updateAppState({ devIsEmailRegisterCheckEnabled: true });
        }
    }, [emailRegisterCheck, updateAppState]);

    // App state
    const { stripe, stripeElements, stripeExpressPaymentType, stripeAvailablePaymentMethods, devIsEmailRegisterCheckEnabled = emailRegisterCheck } = appState;

    // Priority Code
    const priorityCode = useMemo(
        () => UIUtils.getCaseInsensitiveValue(new URLSearchParams(searchParams.toString()), "pcode") || (quote?.affiliateCode ?? quote?.discountCode)?.toLowerCase(),
        [quote?.affiliateCode, quote?.discountCode, searchParams]
    );

    // Form state
    const form = useForm<Quote>({
        resolver: zodResolver(QuoteSchema),
        defaultValues: { underwriter, extra: { queryParams: AnalyticsUtils.getQueryParams(), testUserId } }
    });

    const {
        setValue,
        getValues,
        reset,
        setError,
        control,
        formState: { errors }
    } = form;
    const formValues = useWatch({ control });

    const { resetDirtyValues } = useDirtyValues<Quote>();

    const { otherValuesMap, updateOtherValues, removeOtherValues } = useOtherValuesMap();

    // Start Statsig
    const testLayer = useLayer(PublicConfig.STATSIG_LAYER_ID_MAIN);
    const policyShowFirstName = testLayer.__value?.policyShowFirstName ?? true;
    const policyShowLastName = testLayer.__value?.policyShowLastName ?? true;

    const nameFieldValues = useMemo(
        () => ({ showFirstName: policyShowFirstName, showLastName: policyShowLastName }) as StatsigNameFields,
        [policyShowFirstName, policyShowLastName]
    );
    // End Statsig

    const stepId = useMemo(() => {
        const foundLastStepID = formValues.lastStepID ?? quote?.lastStepID ?? quote?.extra?.lastStepID;
        const defaultStep = (foundLastStepID ?? `pets`) as UsQuoteFormStepIds;
        const lastStepIdDiff = UIUtils.getTimeDiffNow(quote?.extra?.lastStepIDUpdatedAt as string | undefined, "minutes");

        if (!isBillingOutage) {
            if (defaultStep === "billing-outage") {
                return "coverage";
            }
        }

        if (isBillingOutage && defaultStep === "billing") {
            return "billing-outage";
        }

        // If the user has a lastStepID and it's been more than X minutes, send them to the step below
        if (foundLastStepID && lastStepIdDiff > PublicConfig.MAX_RETURN_TO_QUOTE_MINUTES) {
            return "coverage";
        }

        return defaultStep;
    }, [formValues.lastStepID, isBillingOutage, quote?.extra?.lastStepID, quote?.extra?.lastStepIDUpdatedAt, quote?.lastStepID]);

    const [currentStep, setCurrentStep] = useState<UsQuoteFormStepIds>(stepId);

    useEffect(() => {
        if (stepId) {
            setCurrentStep(stepId);
        }
    }, [stepId]);

    // Show reset modal if Quote is in a bad state: invalid quote ID or quote update error
    useEffect(() => {
        if (memoizedUpdateQuote?.isError || memoizedQuoteQuery?.isError) {
            setShowResetModal(true);
        }
    }, [memoizedUpdateQuote?.isError, memoizedQuoteQuery?.isError]);

    useEffect(() => {
        updateAppState({ currentStepID: currentStep });
    }, [updateAppState, currentStep]);

    // Methods

    const resetQuote = useCallback(() => {
        queryClient.clear();
        setShowResetModal(false);
        setQuoteId(``);
        setCurrentStep(`pets`);
        Cookies.remove(COOKIE_QUOTE_ID_KEY);
        resetDirtyValues();
        const newSearchParams = new URLSearchParams(searchParams.toString());
        newSearchParams.delete("quoteId");
        const newUrl = `${pathname}?${newSearchParams.toString()}`;
        reset({ underwriter });
        router.push(newUrl);
    }, [pathname, queryClient, reset, resetDirtyValues, router, searchParams, underwriter]);

    const generateThankYouUrl = useCallback(() => {
        const params = new URLSearchParams(searchParams.toString());
        const _quoteIdInParams = UIUtils.getCaseInsensitiveValue(params, "quoteId");
        const _underwriter = UIUtils.getCaseInsensitiveValue(params, "uw");
        const _quoteId = quote?.id;

        if (!!_quoteId && !_quoteIdInParams) {
            params.set(`quoteId`, _quoteId);
        }

        if (!_underwriter) {
            params.set(`uw`, underwriter);
        }

        return UIUtils.toUrlString(`${PublicConfig.BASE_PATH}/forms/${FORM_ID}/thankyou`, params);
    }, [quote?.id, searchParams, underwriter]);

    const generateQuoteWithRatingAddress = (quote: QuoteFinalized) => {
        const ratingAddressFull: Address = {
            ...quote.ratingAddress,
            city: quote.billingInfo.address?.city ?? ``,
            street1: quote.billingInfo.address?.street1 ?? ``,
            street2: quote.billingInfo.address?.street2 ?? ``,
            country: "US"
        };

        // #statsig - policy-step-name-collection - account for users who don't see the first/last name fields on step 1
        quote.firstName = (quote?.firstName ?? quote.billingInfo.firstName).trim();
        quote.lastName = (quote?.lastName ?? quote.billingInfo.lastName).trim();

        // For partner users (ie, from Forbes) who enter the quote flow with a pre-populated first/last name
        const isPetParent = quote.firstName.toLowerCase() === "pet" && quote.lastName.toLowerCase() === "parent";
        if (isPetParent) {
            quote.firstName = quote.billingInfo.firstName;
            quote.lastName = quote.billingInfo.lastName;
        }

        const quotePayloadWithRatingAddress: Quote = {
            ...quote,
            ratingAddress: ratingAddressFull,
            billingInfo: { frequency: quote.billingInfo.frequency },
            extra: { ...quote.extra }
        };

        return quotePayloadWithRatingAddress;
    };

    const onSubmit: SubmitHandler<QuoteFinalized> = useCallback(
        async values => {
            if (submittingRef.current) {
                throw new Error("PAYMENT - Form is already submitting");
            }
            if (!stripe) {
                throw new Error("PAYMENT - Stripe is not ready");
            }
            if (!stripeElements) {
                throw new Error("PAYMENT - Stripe Elements is not ready");
            }

            // mock errors in development for testing
            if (PublicConfig.ENVIRONMENT === `development` && !!appState.mockBillingError) {
                const asyncErrors = quoteApi.mockBillingErrorToSpotError(appState.mockBillingError);
                updateAppState({ asyncErrors, isQuoteUpdating: false, showLoaderDialog: false });
                return;
            }

            submittingRef.current = true;
            toast.dismiss();

            if (appState.asyncErrors?.length) {
                updateAppState({ asyncErrors: undefined });
            }

            try {
                if (!values.id) throw new Error("PAYMENT - Quote ID is missing");

                const { billingInfo } = values;
                // Update quote with billing address and update quote
                const quoteWithRatingAddress = generateQuoteWithRatingAddress({
                    ...values,
                    extra: { ...values.extra, stripe: { paymentType: stripeExpressPaymentType ?? `card`, availablePaymentMethods: stripeAvailablePaymentMethods } }
                });

                const finalQuote = await memoizedUpdateQuote?.mutateAsync(quoteWithRatingAddress);

                // Create confirmation token to finalize payment
                // https://docs.stripe.com/js/confirmation_tokens/create_confirmation_token
                // https://docs.stripe.com/payments/finalize-payments-on-the-server?platform=web&type=payment#create-ct
                const { error: tokenError, confirmationToken } = await stripe.createConfirmationToken({
                    elements: stripeElements,
                    params: {
                        payment_method_data: {
                            billing_details: {
                                name: `${billingInfo.nameOnCard}`,
                                address: {
                                    line1: billingInfo.address?.street1,
                                    line2: billingInfo.address?.street2,
                                    city: billingInfo.address?.city,
                                    state: billingInfo.address?.state,
                                    postal_code: billingInfo.address?.zipCode,
                                    country: "US"
                                },
                                email: values.email,
                                phone: values.phone
                            }
                        }
                    }
                });

                // Only reached if there's an immediate error when generating the token
                if (tokenError) {
                    throw tokenError;
                    // show generic "check your payment details" error
                }

                if (!confirmationToken?.id) {
                    throw new Error("PAYMENT - Could not generate confirmation token");
                }

                if (!finalQuote?.id) {
                    throw new Error("PAYMENT - Could not update quote");
                }

                const thankYouUrl = generateThankYouUrl();
                const card = confirmationToken.payment_method_preview.card;

                // Update billing information with Stripe token
                await QuoteAPI.updateBillingInformation(finalQuote.id, {
                    billingType: "STRIPE",
                    billingFrequency: billingInfo.frequency === "monthly" ? "Monthly" : "Annually",
                    stripeConfirmationToken: confirmationToken.id,
                    creditCardType: PTZBillingUtils.mapCreditCardBrand(card?.brand ?? ""),
                    expirationDate: PTZBillingUtils.convertStripeExpDetails({ month: card?.exp_month ?? 0, year: card?.exp_year ?? 0 }),
                    accountNumber: card?.last4 ?? "",
                    accountNumberLast4: card?.last4 ?? ""
                });

                // Finalize quote to complete the payment and start the user's policy
                await quoteApi.finalizeQuote(finalQuote.id);

                // Clean up and redirect
                queryClient.clear();
                Cookies.remove(COOKIE_QUOTE_ID_KEY);
                await UIUtils.scrollToTop(3000, 50);
                router.push(thankYouUrl);
            } catch (err) {
                submittingRef.current = false;
                if (err instanceof z.ZodError) {
                    AnalyticsUtils.sendDataDogLog("error", "PAYMENT - Zod validation error in quote submission", {
                        quoteId: values.id,
                        issues: err.issues.map(issue => ({ path: issue.path.join("."), message: issue.message }))
                    });
                    err.issues.forEach(issue => {
                        // Assuming the 'path' of the issue is directly mappable to your form fields
                        // You might need to adjust if your form fields/names are structured differently
                        const path = issue.path.join(".");
                        setError(path as keyof QuoteFinalized, {
                            type: "manual",
                            message: issue.message
                        });
                    });
                }
                if (axios.isAxiosError(err)) {
                    const errorStatus = err?.response?.status ?? 0;

                    // Handle Spot API errors with error ids
                    if (quoteApi.isSpotApiError(err.response?.data)) {
                        const asyncErrors = quoteApi.getErrorIds(err.response?.data);
                        AnalyticsUtils.sendDataDogLog("error", "PAYMENT - Spot API error in quote submission", {
                            quoteId: values.id,
                            errorStatus,
                            asyncErrors
                        });
                        updateAppState({ asyncErrors, isQuoteUpdating: false, showLoaderDialog: false });
                        return;
                    }

                    // Handle non Spot API errors that have some error message (ex: invalid billing zipcode)
                    const error = err.response?.data ?? err.message;
                    if (typeof error === "string" && error.includes("Billing Zipcode") && error.includes("not found")) {
                        const newError = quoteApi.ptzZipcodeErrorToSpotError();
                        const asyncErrors = quoteApi.getErrorIds(newError);
                        AnalyticsUtils.sendDataDogLog("error", "PAYMENT - Billing zipcode error in quote submission", {
                            quoteId: values.id,
                            error,
                            errorStatus,
                            asyncErrors
                        });
                        updateAppState({ asyncErrors, isQuoteUpdating: false, showLoaderDialog: false });
                        return;
                    }

                    // For unhandled errors, throw a generic "unknown" error to be caught by the global error handler (ex: hasUnknownError)
                    if (errorStatus >= 400) {
                        AnalyticsUtils.sendDataDogLog("error", "PAYMENT - Unknown error in quote submission", {
                            quoteId: values.id,
                            errorStatus,
                            errorMessage: err.message
                        });
                        updateAppState({ hasUnknownError: true, isQuoteUpdating: false, showLoaderDialog: false });
                        return;
                    }
                } else {
                    AnalyticsUtils.sendDataDogLog("error", "PAYMENT - Unexpected error in quote submission", {
                        quoteId: values.id,
                        errorMessage: err instanceof Error ? err.message : typeof err === "object" ? JSON.stringify(err) : String(err)
                    });
                }
                throw err;
            }
        },
        [
            appState.asyncErrors?.length,
            appState.mockBillingError,
            generateThankYouUrl,
            memoizedUpdateQuote,
            queryClient,
            quoteApi,
            router,
            setError,
            stripe,
            stripeAvailablePaymentMethods,
            stripeElements,
            stripeExpressPaymentType,
            updateAppState
        ]
    );

    const updateCurrentStep = useCallback(
        (stepId: UsQuoteFormStepIds, quoteId?: string) => {
            const params = new URLSearchParams(searchParams.toString());
            const _quoteIdInParams = UIUtils.getCaseInsensitiveValue(params, "quoteId");
            const _quoteId = quoteId;

            if (!!_quoteId && !_quoteIdInParams) {
                params.set(`quoteId`, _quoteId);
            }

            const newUrl = `${PublicConfig.BASE_PATH}${pathname}?${params.toString()}`;
            window.history.pushState({ currentStep: stepId }, "", newUrl);

            setCurrentStep(stepId);
            resetDirtyValues();
            if (!!appState.asyncErrors?.length) {
                updateAppState({ asyncErrors: undefined });
            }
        },
        [appState.asyncErrors?.length, pathname, resetDirtyValues, searchParams, updateAppState]
    );

    const handleStepChange = useCallback(
        async (newStepIndex: number, newStep: FormStep<Quote, UsQuoteFormStepIds, keyof Quote>, value?: Quote) => {
            if (!newStep?.id) return;

            updateCurrentStep(newStep.id, value?.id);
        },
        [updateCurrentStep]
    );

    // Form
    const { isApplyAllHidden, showBillingSubmitButton } = appState;
    const quoteForm = useMemo(
        () =>
            getUsQuoteForm({
                underwriter,
                updateQuote: setValue,
                updateAppState,
                isUpdating: !!appState?.isQuoteUpdating,
                queryClient,
                setQuoteId,
                onSubmit,
                currentStep,
                priorityCode: priorityCode,
                isApplyAllHidden,
                updateCurrentStep,
                nameFieldValues: nameFieldValues,
                showBillingSubmitButton,
                devIsEmailRegisterCheckEnabled
            }),
        [
            appState?.isQuoteUpdating,
            currentStep,
            isApplyAllHidden,
            nameFieldValues,
            onSubmit,
            priorityCode,
            queryClient,
            setValue,
            showBillingSubmitButton,
            underwriter,
            updateAppState,
            updateCurrentStep,
            devIsEmailRegisterCheckEnabled
        ]
    );

    const { steps } = quoteForm;

    const checkStepsRunningRef = useRef(false);
    const [isStepCheckFinished, setIsStepCheckFinished] = useState(false);

    // This effect runs when a quote does not have a lastStepID value.  It validates the current quote data against the stepSchema and allowContinue
    // methods of each step until it finds a step that is not valid.  It then sets the lastStepID to the id of the invalid step and calls handleStepChange
    useEffect(() => {
        const checkSteps = async () => {
            try {
                for (const [index, stepEntry] of steps.entries()) {
                    const { stepSchema, id, shouldSkip, allowContinue, isMaxDeterministicStep } = stepEntry;
                    if (shouldSkip) {
                        continue;
                    }

                    if (isMaxDeterministicStep) {
                        setValue(`lastStepID`, id);
                        handleStepChange(index, stepEntry, quote);
                        break;
                    }

                    const isValid = stepSchema?.safeParse(quote);

                    if (!!stepSchema && !isValid?.success) {
                        setValue(`lastStepID`, id);
                        handleStepChange(index, stepEntry, quote);
                        break;
                    }

                    if (!!allowContinue) {
                        const canContinue = await allowContinue(quote, otherValuesMap[id]);
                        if (canContinue) {
                            continue;
                        }
                    }

                    setValue(`lastStepID`, id);
                    handleStepChange(index, stepEntry, quote);
                    break;
                }
            } catch (error) {
                console.error("Error in checkSteps:", error);
            } finally {
                setIsStepCheckFinished(true);
            }
        };

        if (!checkStepsRunningRef.current && isFormReady && !submittingRef.current) {
            checkStepsRunningRef.current = true;

            if (!!quote?.id && !formValues.lastStepID && !!steps.length && quote?.quoteStatus !== `finalized`) {
                checkSteps();
            } else {
                setIsStepCheckFinished(true);
            }
        }
    }, [formValues.lastStepID, getValues, handleStepChange, isFormReady, otherValuesMap, quote, setValue, steps]);

    // PRIORITY CODE
    const pcodeCheckRunningRef = useRef(false);

    const browserPCode = UIUtils.getCaseInsensitiveValue(new URLSearchParams(searchParams.toString()), "pcode");

    useEffect(() => {
        // If the browser has a pCode and a quote ID exists, we should update the quote with it. Note this means that last pcode wins and overwrites previous pcode, but
        // the API will overrule us if the new pcode has a lesser discount. So there is no guarantee this pcode will stick!
        const updatePcode = async () => {
            try {
                // If the quote already exists, go ahead and update our API with the new pcode. Otherwise, it will be updated when the form
                // has changes in the future.
                if (!!quote?.id) {
                    const currentValues = getValues();
                    const updatedQuote = await memoizedUpdateQuote?.mutateAsync({
                        ...quote,
                        ...currentValues,
                        discountCode: browserPCode,
                        affiliateCode: browserPCode,
                        lastStepID: currentStep
                    });
                    setValue(`discountCode`, updatedQuote?.discountCode);
                    setValue(`affiliateCode`, updatedQuote?.affiliateCode);
                } else {
                    setValue(`discountCode`, browserPCode);
                    setValue(`affiliateCode`, browserPCode);
                }
            } catch (error) {
                console.error("Error updating priority code");
            }
        };

        if (
            !pcodeCheckRunningRef.current &&
            isStepCheckFinished &&
            !!browserPCode &&
            (quote?.discountCode ?? quote?.affiliateCode) !== browserPCode &&
            isFormReady &&
            !appState?.isQuoteUpdating &&
            quote?.quoteStatus !== `finalized`
        ) {
            pcodeCheckRunningRef.current = true;
            updatePcode();
        }
    }, [appState?.isQuoteUpdating, browserPCode, currentStep, formValues?.lastStepID, getValues, isFormReady, isStepCheckFinished, memoizedUpdateQuote, quote, setValue]);

    const handleSubmitEffects = async (value: Quote, step: FormStep<Quote, UsQuoteFormStepIds, keyof Quote>): Promise<string | void> => {
        /* For any side effects we need to run on submitting a step */
        if (step.allowSubmit && step.allowSubmit(value, otherValuesMap[step.id])) {
        }
    };

    // Effects

    useEffect(() => {
        const handlePopState = async (event: PopStateEvent) => {
            const stateStepId = window.history.state?.currentStep;
            if (stateStepId) {
                setCurrentStep(stateStepId as UsQuoteFormStepIds);
            } else {
                setCurrentStep(`pets`);
            }
        };

        // Add the async event listener
        window.addEventListener("popstate", handlePopState);

        // Clean up the event listener
        return () => window.removeEventListener("popstate", handlePopState);
    }, []);

    useEffect(() => {
        // redirect to /thankyou if the quoteStatus is "finalized"

        if (quote?.quoteStatus === `finalized` && !submittingRef.current) {
            const params = new URLSearchParams(searchParams.toString());
            const _quoteIdInParams = UIUtils.getCaseInsensitiveValue(params, "quoteId");
            const _underwriter = UIUtils.getCaseInsensitiveValue(params, "uw");
            const _quoteId = quote?.id;

            if (!!_quoteId && !_quoteIdInParams) {
                params.set(`quoteId`, _quoteId);
            }

            if (!_underwriter) {
                params.set(`uw`, underwriter);
            }

            const newUrl = UIUtils.toUrlString(`${PublicConfig.BASE_PATH}/forms/${FORM_ID}/thankyou`, params);
            router.push(newUrl, { scroll: false });
        }
    }, [quote?.id, quote?.quoteStatus, resetQuote, router, searchParams, underwriter]);

    useEffect(() => {
        // Push underwriter value into AppLayer for use in other components
        if (!!underwriter) {
            updateAppState({ underwriter });
        }
    }, [underwriter, updateAppState]);

    useEffect(() => {
        // Sets initial form values once the quote ID is available
        if (!initialValuesLoadedRef.current && !quoteIdParam) {
            initialValuesLoadedRef.current = true;
            setIsFormReady(true);
        }

        if (!initialValuesLoadedRef.current && quoteId && !!quote?.id) {
            initialValuesLoadedRef.current = true;
            const allValues = getValues();
            const merged = { ...quote, ...allValues, underwriter, extra: { ...quote.extra } };
            reset(merged);
            setIsFormReady(true);
        }
    }, [quoteId, quote, reset, getValues, quoteIdParam, formValues.id, underwriter, formValues.lastStepID]);

    useEffect(() => {
        // Here we update the form values from the API data
        if (!!quote?.id) {
            reset(quote);

            if (PublicConfig.ENVIRONMENT === `development`) {
                StorageUtils.setItem(`dev-quote`, JSON.stringify(quote));
            }
        }
    }, [quote, reset]);

    useEffect(() => {
        if (!!memoizedQuoteQuery) {
            updateAppState({ quoteQuery: memoizedQuoteQuery });
        }
    }, [memoizedQuoteQuery, updateAppState]);

    useEffect(() => {
        if (!!memoizedUpdateQuote) {
            updateAppState({ updateQuote: memoizedUpdateQuote });
        }
    }, [memoizedUpdateQuote, updateAppState]);

    useEffect(() => {
        updateAppState({ isQuoteUpdating: isQuoteUpdating });
    }, [isQuoteUpdating, updateAppState]);

    useEffect(() => {
        updateAppState({ breakpoint: currentBreakpoint });
    }, [currentBreakpoint, updateAppState]);

    useEffect(() => {
        updateAppState({ priorityCode: priorityCode });
    }, [priorityCode, updateAppState]);

    //
    // NOTIFICATIONS
    //

    const handleNotificationClose = useCallback(
        (notificationToUpdate: z.infer<typeof notificationSchema>) => {
            if (submittingRef.current) return;
            const currentExtra = getValues("extra");
            const existingNotifications = currentExtra?.notifications || [];

            // Check if the notification already exists based on both id and type
            const notificationIndex = existingNotifications.findIndex(
                notification => notification.id === notificationToUpdate.id && notification.type === notificationToUpdate.type
            );

            if (notificationIndex > -1) {
                // Update existing notification
                existingNotifications[notificationIndex] = { ...existingNotifications[notificationIndex], ...notificationToUpdate };
            } else {
                // Notification doesn't exist, append the new notification
                existingNotifications.push(notificationToUpdate);
            }

            const updatedExtra = {
                ...currentExtra,
                notifications: existingNotifications
            };
            // Update the whole state with the new notifications array
            setValue("extra", updatedExtra);
        },
        [getValues, setValue]
    );

    const formNotifications: Notifications = useMemo(() => {
        const amazonGiftCardToast = formValues?.extra?.notifications?.find(toast => toast.type === "toast" && toast.id === "amazon-gift-card");

        return {
            toasts: [
                {
                    id: "amazon-gift-card",
                    type: "toast",
                    message: <AmazonToast state={formValues?.ratingAddress?.state} />,
                    visible:
                        !!formValues?.id &&
                        !submittingRef.current &&
                        formValues?.quoteStatus !== `finalized` &&
                        NotificationUtils.isAmazonEligible(formValues?.ratingAddress?.state) &&
                        NotificationUtils.shouldShowNotification(amazonGiftCardToast?.lastClosed, amazonGiftCardToast?.frequency),
                    duration: Infinity,
                    position: "top-center",
                    lastClosed: amazonGiftCardToast?.lastClosed,
                    frequency: "weekly",
                    isCloseDisabled: appState?.isQuoteUpdating,
                    onClose: () => handleNotificationClose({ id: "amazon-gift-card", type: "toast", frequency: "weekly", lastClosed: new Date().toISOString() })
                }
            ]
        };
    }, [formValues?.extra?.notifications, formValues?.ratingAddress?.state, formValues?.id, formValues?.quoteStatus, appState?.isQuoteUpdating, handleNotificationClose]);

    useNotifications({ notifications: formNotifications });

    const shouldShowLoader = !!quote?.id && !formValues.lastStepID && !isStepCheckFinished;
    const loaderText = (!quoteId && !quoteIdParam) || quote?.quoteStatus === "finalized" ? "Loading..." : "Picking up where you left off...";

    return (
        <>
            {appState.showLoaderDialog && <LoaderModal />}
            <QuoteResetModal onReset={resetQuote} isVisible={showResetModal} />
            <FormParentContextProvider methods={form}>
                {shouldShowLoader ? (
                    <div className="relative flex min-h-screen items-center justify-center">
                        <Container>
                            <LoaderWithText text={loaderText} />
                        </Container>
                    </div>
                ) : (
                    <Form
                        key={currentStep}
                        form={quoteForm}
                        initialStepID={currentStep}
                        value={formValues}
                        otherValuesMap={otherValuesMap}
                        isUpdating={appState?.isQuoteUpdating}
                        onStepChange={handleStepChange}
                        onSubmitStep={handleSubmitEffects}
                        uwConfig={{ phone: Strings.PTZ_US.PHONE_NUMBER, hours: Strings.PTZ_US.HOURS }}
                    />
                )}
            </FormParentContextProvider>
            {modal.render}
        </>
    );
}
