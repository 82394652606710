import React, { useState } from "react";
import phone from "phone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCreditCard } from "@fortawesome/pro-regular-svg-icons";
import { StripeExpressCheckoutElementConfirmEvent, StripeExpressCheckoutElementReadyEvent, StripeExpressCheckoutElementClickEvent } from "@stripe/stripe-js";
import { ExpressCheckoutElement } from "@stripe/react-stripe-js";
import StripePaymentElement from "./StripePaymentElement";
import StripeUtils from "./StripeUtils";
import { Divider } from "@/shared/components/ui/Divider";
import { useAppLayerContext } from "@/shared/contexts/AppLayer";
import { FieldPath, useFormContext } from "react-hook-form";
import { StripeExpressPaymentSchema } from "@/shared/types/Quote.interface";
import { AllBillingStepProps } from "../BillingEditor";
import { UIUtils } from "@/shared/utils/UIUtils";
import { cn } from "@/shared/utils";
import { Button } from "@/shared/components/ui";
import { AnalyticsUtils } from "@/shared/utils/AnalyticsUtils";

const MAX_RETRIES = 3;
const RETRY_DELAY = 500;

type OnConfirmResult = {
    success: boolean;
    retryCount: number;
};

export default function StripeCheckout() {
    const [hasExpressPaymentMethod, setHasExpressPaymentMethod] = useState(false);
    const { appState, updateAppState } = useAppLayerContext();
    const { formContinueButtonRef, showCreditCardFields, isQuoteUpdating } = appState;
    const { getValues, setValue, setError, setFocus, watch } = useFormContext<AllBillingStepProps>();
    const currentPhone = watch("phone");

    // Click pay on wallet modal
    const onConfirm = async (event: StripeExpressCheckoutElementConfirmEvent, retryAttempt: number = 0): Promise<OnConfirmResult> => {
        AnalyticsUtils.sendDataDogAction("Stripe Express Checkout Confirm Clicked");
        const { billingDetails } = event;
        let zipCodeValue = billingDetails?.address.postal_code ?? "";
        if (zipCodeValue.length > 5) {
            zipCodeValue = zipCodeValue.slice(0, 5);
        }

        setValue("billingInfo.address.city", billingDetails?.address.city ?? "");
        setValue("billingInfo.address.state", billingDetails?.address.state ?? "");
        setValue("billingInfo.address.country", billingDetails?.address.country ?? "");
        setValue("billingInfo.address.zipCode", zipCodeValue);
        setValue("billingInfo.address.street1", billingDetails?.address.line1 ?? "");
        setValue("billingInfo.address.street2", billingDetails?.address.line2 ?? "");

        if (!currentPhone) {
            const formattedPhone = phone(billingDetails?.phone ?? "");
            const transformedPhone = UIUtils.transformPhoneNumber(formattedPhone.phoneNumber ?? "");
            setValue("phone", transformedPhone);
        }

        if (formContinueButtonRef?.current?.click) {
            formContinueButtonRef.current.click();
            return { success: true, retryCount: retryAttempt };
        } else if (retryAttempt < MAX_RETRIES) {
            AnalyticsUtils.sendDataDogLog("warn", `PAYMENT - Retry attempt ${retryAttempt + 1} for clicking continue button`);
            console.log(`Retry attempt ${retryAttempt + 1} for clicking continue button`);
            await new Promise(resolve => setTimeout(resolve, RETRY_DELAY));
            return onConfirm(event, retryAttempt + 1);
        } else {
            console.error("Failed to click the continue button after maximum retries");
            return { success: false, retryCount: retryAttempt };
        }
    };

    const onExpressCheckoutLoad = (event: StripeExpressCheckoutElementReadyEvent) => {
        const { availablePaymentMethods } = event;

        if (availablePaymentMethods) {
            const hasPaymentMethod = Object.values(availablePaymentMethods).some(value => !!value);
            setHasExpressPaymentMethod(hasPaymentMethod);
            updateAppState({ showCreditCardFields: false, showBillingSubmitButton: false, stripeAvailablePaymentMethods: availablePaymentMethods });
        } else {
            updateAppState({ showCreditCardFields: true, showBillingSubmitButton: true });
        }
    };

    const onExpressCheckoutElementClick = (event: StripeExpressCheckoutElementClickEvent) => {
        if (isQuoteUpdating) return;
        updateAppState({ showCreditCardFields: false, showBillingSubmitButton: false });

        const { expressPaymentType } = event;

        const currentValues = getValues();
        const result = StripeExpressPaymentSchema.safeParse(currentValues);

        if (!result.success) {
            let isFirstError = true;
            result.error.errors.forEach(err => {
                const fieldName = err.path.join(".") as FieldPath<typeof currentValues>;
                setError(fieldName, {
                    type: "manual",
                    message: err.message
                });
                if (isFirstError) {
                    // we need to delay setFocus call by 500ms here to make sure our animation state has finished
                    setTimeout(() => setFocus(fieldName as FieldPath<AllBillingStepProps>), 600);
                    isFirstError = false;
                }
            });

            return;
        }

        if (expressPaymentType) {
            updateAppState({ stripeExpressPaymentType: expressPaymentType, showBillingSubmitButton: true });
        }
        AnalyticsUtils.sendDataDogAction("Stripe Express Checkout Clicked", { expressPaymentType });
        event.resolve({ phoneNumberRequired: !currentPhone });
    };

    const onExpressCheckoutCancel = () => {
        updateAppState({ showCreditCardFields: false, showBillingSubmitButton: false, stripeExpressPaymentType: undefined });
        AnalyticsUtils.sendDataDogAction("Stripe Express Checkout Cancelled");
    };

    const conditionalExpressClasses = {
        "my-5": hasExpressPaymentMethod,
        "opacity-50": isQuoteUpdating
    };

    return (
        <>
            <div id="express-checkout-element" className={cn(conditionalExpressClasses)}>
                <ExpressCheckoutElement
                    options={StripeUtils.expressPaymentOptions}
                    onReady={onExpressCheckoutLoad}
                    onClick={onExpressCheckoutElementClick}
                    onConfirm={onConfirm}
                    onCancel={onExpressCheckoutCancel}
                />
            </div>

            {hasExpressPaymentMethod && <Divider>Or pay with a card</Divider>}

            {showCreditCardFields ? (
                <StripePaymentElement />
            ) : (
                <Button
                    variant="default"
                    className="mt-5"
                    startDecorator={<FontAwesomeIcon icon={faCreditCard} className="size-5" />}
                    onClick={() => updateAppState({ showCreditCardFields: true, showBillingSubmitButton: true, stripeExpressPaymentType: undefined, asyncErrors: [] })}
                >
                    Credit/Debit Card
                </Button>
            )}
        </>
    );
}
