import { UIUtils } from "@/shared/utils/UIUtils";
import { Heading } from "@/shared/components/ui";
import { cn } from "@/shared/utils";

interface PolicySummaryFooterProps {
    policiesCount: number;
    transactionFee: number | undefined;
    isAnnualBilling: boolean;
    totalPrice: number;
    isSingleCol?: boolean;
}

const PolicySummaryFooter = (props: PolicySummaryFooterProps) => {
    const { policiesCount, transactionFee = 0, isAnnualBilling, totalPrice, isSingleCol = false } = props;

    const formattedTotalPrice = UIUtils.formatNumber(totalPrice ?? 0, 2);

    return (
        <div className={cn("grid gap-4 text-content-primary sm:mt-4", !isSingleCol && "sm:grid-cols-2 sm:gap-12")}>
            <div></div>
            <div className="flex flex-col gap-2">
                {(transactionFee ?? 0) > 0 ? (
                    <div className="flex flex-row justify-between text-xs">
                        <p className="text-content-secondary">Monthly Transaction Fee</p>
                        <p>
                            <b>{UIUtils.formatCurrency(transactionFee ?? 0)}</b>
                        </p>
                    </div>
                ) : (
                    <div className="h-4 w-full"></div>
                )}
                <div className="flex flex-row justify-between">
                    <Heading level="h4" className="text-base font-semibold">
                        Grand Total
                    </Heading>
                    <div className="font-semibold">
                        <span data-testid="policy-summary-total-price" data-value={formattedTotalPrice}>
                            ${formattedTotalPrice}
                        </span>
                        /{isAnnualBilling ? "yr" : "mo"}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PolicySummaryFooter;
